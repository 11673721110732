import { drop } from 'ramda'

import { findMatchingElements, WILDCARD } from '@/util/sonataHelper'
import EventBus from '@/util/EventBus'

const $ = window.$


const DISABLED_LINK_CLASS = 'btn-link--sort-disabled'

/**
 * Swap two elements in the DOM.
 *
 * @see https://stackoverflow.com/a/38515050
 */
const swap = ($el1, $el2) => {
    // create temporary placeholder
    const $temp = $('<div>')

    // 3-step swap
    $el1.before($temp)
    $el2.before($el1)
    $temp.after($el2).remove()

    return $el1
}

/**
 * Disable the up arrow for the first and the down arrow for the last
 * element.
 */
const renderSortArrows = ($collection, index, length) => {
    $collection.find('.js-sort-up')[index === 0 ? 'addClass' : 'removeClass'](DISABLED_LINK_CLASS)
        .tooltip(index === 0 ? 'disable' : 'enable')

    $collection.find('.js-sort-down')[index + 1 === length ? 'addClass' : 'removeClass'](DISABLED_LINK_CLASS)
        .tooltip(index + 1 === length ? 'disable' : 'enable')
}


const initSortableCollection = ($element) => {
    const _sortFieldName = $element.data('sort-field')
    const _$container = $element.closest('.sonata-ba-field')
    // Sonata way of accessing form fields in collection :)
    const _sortFieldSelector = `${$element.attr('id')}_${WILDCARD}_${_sortFieldName}`

    const _renderAndUpdateHtml = () => {
        _$container.find('.js-sort-down, .js-sort-up').each(function () {
            const $dataCollection = $(this).closest('[data-collection-id]')

            if ($dataCollection.hasClass('js-sortable-collection')) {
                $(this).removeClass('is-hidden')
            }
        })
        const $all = _$container.find('.sonata-collection-row')
        $all.get().map((x, index) => renderSortArrows($(x), index, $all.length))

        // make sure each sort field has a unique value
        findMatchingElements(_sortFieldSelector).forEach(($field, index) => {
            $field.val(index + 1)
        })
    }

    const _getSortField = ($elem) => {
        const formRowId = $elem.find('.js-form-row').attr('id')
        const id = `${drop('sonata-ba-field-container-'.length, formRowId)}_${_sortFieldName}`

        return $(`#${id}`)
    }

    const _swapOrder = ($elem1, $elem2) => {
        swap($elem1, $elem2)
        const sortField1 = _getSortField($elem1)
        const sortField2 = _getSortField($elem2)

        const val1 = sortField1.val()
        sortField1.val(sortField2.val())
        sortField2.val(val1)
        _renderAndUpdateHtml()
    }

    // ----------------------------------------------------
    // events and rendering logic

    _renderAndUpdateHtml()
    EventBus.$on('addCollectionTypeElement', _renderAndUpdateHtml)

    _$container.on('click', '.js-sort-down', function (event) {
        event.preventDefault()
        const $link = $(this)
        if ($link.hasClass(DISABLED_LINK_CLASS)) return

        const $element = $link.closest('.sonata-collection-row')
        const $nextElement = $element.next()
        _swapOrder($element, $nextElement)
    })

    _$container.on('click', '.js-sort-up', function (event) {
        event.preventDefault()
        const $link = $(this)
        if ($link.hasClass(DISABLED_LINK_CLASS)) return

        const $element = $link.closest('.sonata-collection-row')
        const $prevElement = $element.prev()
        _swapOrder($element, $prevElement)
    })
}

/**
 * Allow collection types to be sortable via the `.js-sortable-colleciton` class.
 *
 * The sort order is stored in a hidden field, where the name can be specified
 * via `data-sort-field`.
 *
 * @see https://extranet.snoopmedia.com/issuetracker/view.php?id=34752
 * @example
 *
 * <div
 *     id="s5e4525aed8f2f_upload_galleryElements"
 *     class="... js-sortable-collection"
 *     data-sort-field="sortOrder"
 * >
 *     ...
 *     <div class="c-form-group c-form-group--collection c-collection sonata-collection-row">
 *         <input
 *             type="hidden"
 *             id="s5e4525aed8f2f_upload_galleryElements_0_sortOrder"
 *             value="1"
 *         />
 *         ...
 *     </div>
 *     <div class="c-form-group c-form-group--collection c-collection sonata-collection-row">
 *         <input
 *             type="hidden"
 *             id="s5e4525aed8f2f_upload_galleryElements_0_sortOrder"
 *             value="2"
 *         />
 *         ...
 *     </div>
 * </div>
 */
export default {
    init (root = document.body) {
        Array.from(root.querySelectorAll('input.js-sortable-collection'))
            .forEach(x => initSortableCollection($(x)))
    },
}

